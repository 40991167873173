
  var editorScriptEntry = require('/home/builduser/work/f35f652dcafd0a82/packages/map-plug-in/src/editor.app.ts');

  
    if (editorScriptEntry.default) {
      module.exports = editorScriptEntry.default;
    } else {
      const { editorScriptBuilder } = require('@wix/bob-widget-services');
      const { editorReadyWrapper, createFlowAPIFabric, exportsWrapper } = require('@wix/yoshi-flow-editor/runtime/esm/editorScript.js');

      const biLogger = require('/home/builduser/work/f35f652dcafd0a82/packages/map-plug-in/target/generated/bi/createBILogger.ts').createOwnerBILogger;

      

  const { initI18n: initI18n } = require('@wix/yoshi-flow-editor/runtime/esm/i18next/init');


      

    var createExperiments = null;
    var createWidgetExperiments = null;
    

      
const { createHttpClient } = require('@wix/yoshi-flow-editor/runtime/esm/controller/httpClientProp');
    


      const sentryConfig = null;
      const experimentsConfig = {"scopes":["map-plugin"],"centralized":true};
      const translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/f35f652dcafd0a82/packages/map-plug-in/src/assets/locales/messages_en.json","availableLanguages":["en"]};
      const defaultTranslations = {"map-plugin.widget.serviceLocation":"Service Location","map-plugin.widget.businessLocation":"Business Location","map-plugin.widget.display-name":"Map Plugin","map-plugin.gfpp.main-action1":"Manage Locations","map-plugin.panel.settings.title":"Map Plugin","map-plugin.panel.settings.location-options.title":"What should the map show?","map-plugin.panel.settings.location-options.service-location":"Service location","map-plugin.panel.settings.location-options.business-location":"Business location","map-plugin.panel.settings.edit-your-locations":"You can add or edit your business locations.","map-plugin.panel.settings.button.business-info.label":"Go to Business info","map-plugin.panel.settings.empty-state.label":"To display your location on the map, you need to create a service for your site.","map-plugin.panel.settings.empty-state.button.new-service.label":"Add a New Service"};

      const createFlowAPI = createFlowAPIFabric({
        sentryConfig,
        experimentsConfig,
        translationsConfig,
        defaultTranslations,
        biLogger,
        shouldUseEssentials: true,
        artifactId: 'map-plug-in',
        appDefId: 'c5fedde3-63c7-4041-a5fb-c77bbe6308dc',
        optionalDeps: {
          initI18n,
          createExperiments,
          createHttpClient
        },
        localeDistPath: 'assets/locales',
      });

      const useAPIsFromExports = typeof editorScriptEntry.exports === "function";

      function wrapEditorReady(eReady) {
        try {
          return editorReadyWrapper({
            createFlowAPI,
            editorReady: eReady,
            shouldSkipAPIOverrides: useAPIsFromExports,
          });
        } catch (e) {
          console.error(e);
          throw e;
        }
      }

      let wrappedEditorReady = wrapEditorReady(editorScriptEntry.editorReady);
      if (false) {
        const hot = require('@wix/yoshi-flow-editor/build/cjs/wrappers/editorScriptHot.js').hot;
        wrappedEditorReady = hot(module, wrappedEditorReady);
      }

      let builder = editorScriptBuilder();
      if (editorScriptEntry.editorReady) {
        builder = builder.withEditorReady(wrappedEditorReady);
      }

      if (editorScriptEntry.exports) {
        builder = builder.withExports(useAPIsFromExports ? exportsWrapper(editorScriptEntry.exports) : editorScriptEntry.exports);
      }

      if (editorScriptEntry.onEvent) {
        builder = builder.withEventHandler(editorScriptEntry.onEvent);
      }
      const controllerWidgetManifests = [];
      
      const userController_0 = require('/home/builduser/work/f35f652dcafd0a82/packages/map-plug-in/src/components/Widget1/editor.controller.ts');

      const model_0 = require('/home/builduser/work/f35f652dcafd0a82/packages/map-plug-in/src/components/Widget1/model.ts').default;

      const manifest_0 = userController_0 ? userController_0.default || userController_0 : {};
      if (!manifest_0.type) {
        manifest_0.type = "c5fedde3-63c7-4041-a5fb-c77bbe6308dc-pkwg7";
      }
      if (!manifest_0.getExports && model_0) {
        manifest_0.getExports = () => ({"c5fedde3-63c7-4041-a5fb-c77bbe6308dc-pkwg7": model_0.getExports()});
      }
      if (userController_0 && userController_0.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "c5fedde3-63c7-4041-a5fb-c77bbe6308dc-pkwg7", getWidgetManifest: userController_0.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_0);

      if (editorScriptEntry.getAppManifest || controllerWidgetManifests.length) {
        const customGetAppManifest = async (...params) => {
          const [{ appManifestBuilder }, editorSDK, contextParams] = params;
          const flowAPI = await createFlowAPI(editorSDK, contextParams);
          controllerWidgetManifests.forEach(({ widgetId, getWidgetManifest}) => {
            appManifestBuilder.configureWidget(widgetId, (builder) => getWidgetManifest(builder, editorSDK, flowAPI));
          });

          if (editorScriptEntry.getAppManifest) {
            return editorScriptEntry.getAppManifest(...params, flowAPI);
          }

          return appManifestBuilder.build();
        };
        builder = builder.withAppManifest(customGetAppManifest);
      }
      var result = builder.build();
      module.exports = result;
  }
  
