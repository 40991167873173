import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  editorSDK,
  flowAPI,
) => {
  const { t } = flowAPI.translations;
  widgetBuilder.set({
    displayName: t('map-plugin.widget.display-name'),
    nickname: 'mapPlugin',
  });
};
