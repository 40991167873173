import { EditorSDK, EditorReadyOptions } from '@wix/platform-editor-sdk';
import type { WidgetInstallationType } from '@wix/platform-editor-sdk';

const presets = { Desktop: 'variants-lee0z9u4' };

export const onEditorReady = async (
  editorSDK: EditorSDK,
  appDefId: string,
  options: EditorReadyOptions,
): Promise<void> => {
  if (options.firstInstall) {
    // await editorSDK.application.appStudioWidgets.addWidget('', {
    //   widgetId: '2f7017d1-59a0-4f01-8eef-638225d6df53',
    //   installationType: 'closed' as WidgetInstallationType,
    //   scopedPresets: {
    //     desktop: {
    //       layout: presets.Desktop,
    //       style: presets.Desktop,
    //     },
    //   },
    //   layout: {
    //     height: 300,
    //     width: 700,
    //     x: 0,
    //     y: 100,
    //   },
    //   layouts: {
    //     componentLayout: {
    //       minHeight: {
    //         type: 'px',
    //         value: 280,
    //       },
    //       hidden: false,
    //       height: {
    //         type: 'auto',
    //       },
    //       type: 'ComponentLayout',
    //       width: {
    //         type: 'auto',
    //       },
    //     },
    //     itemLayout: {
    //       id: '',
    //       alignSelf: 'center',
    //       gridArea: {
    //         rowStart: 1,
    //         rowEnd: 2,
    //         columnStart: 1,
    //         columnEnd: 2,
    //       },
    //       justifySelf: 'center',
    //       type: 'GridItemLayout',
    //     },
    //   },
    // });
  }
};
