import { onEditorReady } from './editor-app/editorReady';
import { registerEventListeners } from './editor-app/eventHandlers';
import { AppManifest } from '@wix/app-manifest-builder';
import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { WidgetComponentIds as ComponentIds } from './components/Widget1/config/components-ids';
import { CONTROLLER_ID } from './constants/components';

export const editorReady = async (editorSDK, appDefId, options, flowAPI) => {
  await registerEventListeners(editorSDK, options, flowAPI);
  return onEditorReady(editorSDK, appDefId, options);
};

export const getAppManifest = async (
  options,
  editorSDK,
  contextParams,
  flowAPI,
): Promise<AppManifest> => {
  const { t } = flowAPI.translations;
  const { appManifestBuilder } = options;
  const baseManifest = await fetchWidgetsStageData(options);
  const role = ComponentIds.googleMaps.substring(1);

  const isStudio =
    contextParams.origin.subType === 'STUDIO' ||
    contextParams.origin.subType === 'EDITOR_X';

  // temporary solution for https://wix.atlassian.net/browse/EDIX-26985
  const settingsIconGFPPoverride = isStudio
    ? { behavior: 'HIDE' }
    : { target: { role } };

  appManifestBuilder.configureWidget(CONTROLLER_ID, (widgetBuilder) => {
    widgetBuilder
      .gfpp()
      .set('mainAction1', {
        label: t('map-plugin.gfpp.main-action1'),
        actionId: 'settings',
      })
      .set('settings', settingsIconGFPPoverride)
      .set('design', { target: { role } })
      .set('connect', { behavior: 'HIDE' })
      .set('help', { id: 'e2089a18-7a6f-4f7c-8c74-3a330c5efa4d' });
  });

  return appManifestBuilder.withJsonManifest(baseManifest).build();
};
