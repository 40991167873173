import { PanelsApiFactory } from '@wix/blocks-widget-services/panels';
import { EditorReadyOptions, EditorSDK } from '@wix/platform-editor-sdk';
import { FlowAPI } from '@wix/yoshi-flow-editor';
import { APP_ID } from '../constants/components';
import { WIDGET_GFPPS } from '../constants/gfpps';

let COMP_REP;

export const registerEventListeners = async (
  editorSDK: EditorSDK,
  options: EditorReadyOptions,
  flowAPI: FlowAPI,
) => {
  const customPanelOptions = {
    editorSDK,
    editorType: options.origin.type,
    essentials: flowAPI.essentials,
  };

  const panelsApi = await new PanelsApiFactory().createPanelsApi(
    customPanelOptions,
  );
  await editorSDK.addEventListener('widgetGfppClicked', (event) => {
    const { id, componentRef } = event.detail;
    switch (id) {
      case WIDGET_GFPPS.SETTINGS:
        COMP_REP = componentRef;
        void panelsApi.openBlocksPanel('Settings', componentRef, {
          overrides: {
            title: flowAPI.translations.t('map-plugin.panel.settings.title'),
          },
        });
        break;
    }
  });
  await editorSDK.addEventListener('appVisitedInDashboard', (event) => {
    console.log({ event, COMP_REP });
    const { visitedAppDefIds } = event.detail;
    if (COMP_REP && (!visitedAppDefIds[0] || visitedAppDefIds[0] === APP_ID)) {
      void panelsApi.openBlocksPanel('Settings', COMP_REP);
    } else {
      COMP_REP = undefined;
    }
  });
};
